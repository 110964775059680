import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { languages } from '@nemlia/talvatranslations'
import LanguageDetector from 'i18next-browser-languagedetector'

const detectionOptions = {
  order: [`querystring`, `localStorage`, `subdomain`, `navigator`, `htmlTag`],
  lookupFromPathIndex: 0,
  lookupQuerystring: 'lng',
}

const customInterpolation = {
  type: 'postProcessor',
  name: 'customInterpolation',
  process: (value, _key, options) => {
    // Check if double brackets are present
    if (/\{\{[^{}]+\}\}/.test(value)) {
      // Return the original value without any replacement
      return value
    }

    // Replace occurrences of {variable} with the corresponding value from options
    return value.replace(/\{([^{}]+)\}/g, (match, key) => options[key] || match)
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(customInterpolation)
  .init({
    // lng: `fo`,
    resources: languages,
    fallbackLng: `en`,
    detection: detectionOptions,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    postProcess: ['customInterpolation'],
  })

export default i18n
