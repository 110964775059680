import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { useAuth0 } from '@auth0/auth0-react'
import { useSetUserLanguage } from '../../operations/mutations/setUserLanguage'
import { getListOfLanguages } from '../../utils/helpers'

export default function LanguageSelector() {
  const [selectorOpen, setSelectorOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation()
  const { user } = useAuth0()
  const current_language = i18n.language

  const languages = getListOfLanguages().filter(
    (language) => language.value !== current_language
  )

  const { mutate: setUserLanguage } = useSetUserLanguage(() => {
    setLoading(false)
  })

  return (
    <Container>
      <CaptionText>{t(`settings.current_language`)}</CaptionText>
      <Button disabled={loading} onClick={() => setSelectorOpen(!selectorOpen)}>
        <ButtonText>
          {loading
            ? '...'
            : getListOfLanguages().find((lang) => {
                return lang.value === current_language
              })?.label}{' '}
          <IconStyle icon={faGlobe} />
        </ButtonText>
        <OtherLanguages selectorOpen={selectorOpen}>
          {languages.map((language, i) => (
            <OtherLanguage
              key={language.value}
              onClick={() => {
                i18n.changeLanguage(language.value)
                setSelectorOpen(!selectorOpen)

                setLoading(true)
                setUserLanguage({
                  variables: {
                    user_language: language.value,
                    email: user?.email,
                  },
                })
              }}
            >
              {language.label}
            </OtherLanguage>
          ))}
        </OtherLanguages>
      </Button>
    </Container>
  )
}

const Container = styled.div`
  font-size: 18px;
  margin: 0 0 20px 0;
`

const CaptionText = styled.div`
  font-weight: 700;
`

const Button = styled.button`
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  background-color: #50af9f;
  border: 0;
  padding: 10px 30px;
  outline: none;
`

const ButtonText = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-align: left;
`

const IconStyle = styled(FontAwesomeIcon)`
  margin: 0 0 0 10px;
  font-size: 24px;
`

type OtherLanguagesProps = {
  selectorOpen: boolean
}

const OtherLanguages = styled.ul<OtherLanguagesProps>`
  display: ${(props) => (props.selectorOpen ? `block` : `none`)};
  list-style-type: none;
`

const OtherLanguage = styled.li`
  color: #000000;
  font-size: 24px;
  padding: 10px 0;
`
