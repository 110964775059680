import { gql, useSubscription } from '@apollo/client'

interface Input {
  id: string
}

export interface SubscriptionCitizen {
  id: string
  updated_at: string
  ta_citizen_gateways: [
    {
      archived: boolean
      ta_gateway: {
        id: string
        online: boolean
        serial: string
      }
    }
  ]
}
interface Response {
  ta_citizen: SubscriptionCitizen[]
}

const SUBSCRIBE_TO_CITIZEN = gql`
  subscription SubscribeToCitizen($id: uuid!) {
    ta_citizen(where: { id: { _eq: $id } }) {
      id
      updated_at
      ta_citizen_gateways(where: { archived: { _eq: false } }) {
        archived
        ta_gateway {
          id
          online
          serial
        }
      }
    }
  }
`

export const useSubscribeToCitizen = (input: Input) => {
  const { data, loading, error } = useSubscription<Response, Input>(
    SUBSCRIBE_TO_CITIZEN,
    {
      variables: input,
    }
  )

  return { data, loading, error }
}
