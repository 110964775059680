import { gql, useMutation } from '@apollo/client'

interface MutateResponse {
  id: string
  email: string
  user_language: string
}

interface MutateInput {
  user_language: string
  email?: string
}

const DEFAULT_LANGUAGE_MUTATION = gql`
  mutation DefaultLanguageMutation($user_language: String!, $email: String!) {
    update_ta_user(
      where: { email: { _eq: $email } }
      _set: { user_language: $user_language }
    ) {
      affected_rows
      returning {
        id
        email
        user_language
      }
    }
  }
`

export function useSetUserLanguage(
  onCompleted: (data: MutateResponse) => void
) {
  const [mutate, { error, data }] = useMutation<MutateResponse, MutateInput>(
    DEFAULT_LANGUAGE_MUTATION,
    {
      onCompleted: onCompleted,
    }
  )

  return { mutate, data, error }
}
