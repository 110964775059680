import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import nemliatalvalogo from '../../images/nemliatalvalogo.png'
import { breakpoints } from '../../utils/breakpoints'
import { SettingsButton } from './SettingsButton'
import { NotificationOverviewButton } from './NotificationOverviewButton'

const Content = () => {
  const { isAuthenticated } = useAuth0()

  if (!isAuthenticated) return null

  return (
    <Container>
      <ImageContainer>
        <ImageStyle src={nemliatalvalogo} alt="Nemlia Talva" />
      </ImageContainer>
      <HeaderContainer>
        <HeaderMenu />
      </HeaderContainer>
    </Container>
  )

  function HeaderMenu() {
    return (
      <>
        <NotificationOverviewButtonStyle>
          <NotificationOverviewButton />
        </NotificationOverviewButtonStyle>
        <SettingsButtonStyle>
          <SettingsButton />
        </SettingsButtonStyle>
      </>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
`

const ImageStyle = styled.img`
  width: 200px;

  @media (min-width: ${breakpoints.small}px) {
    width: auto;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;

  @media (min-width: ${breakpoints.small}px) {
    margin: 0;
  }
`

const SettingsButtonStyle = styled.div``

const NotificationOverviewButtonStyle = styled.div``

export default Content
