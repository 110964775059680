import React from 'react'

const context: any = {
  currentCitizen: null,
  availableCitizens: [],
  setCitizenId: () => {},
  gatewayOnline: true,
  currentUser: null,
}

export const MainContext = React.createContext(context)
