import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Auth0Provider } from '@auth0/auth0-react'
import { GraphQLProvider } from './utils/Apollo'
import './i18n'
import ApplicationInsights from './utils/applicationinsights'

function app() {
  function RenderProvider() {
    return (
      <React.StrictMode>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
          clientId={process.env.REACT_APP_AUTH0_CLIENTID || ''}
          authorizationParams={{
            redirect_uri: window.location.origin + '/callback',
            audience: 'hasura',
          }}
        >
          <GraphQLProvider>
            <App />
          </GraphQLProvider>
        </Auth0Provider>
      </React.StrictMode>
    )
  }

  function RenderDom() {
    return <ApplicationInsights>{RenderProvider()}</ApplicationInsights>
  }

  const rootElement = document.getElementById('root')

  const root = ReactDOM.createRoot(rootElement as HTMLElement)

  root.render(RenderDom())
}

app()

serviceWorker.unregister()
