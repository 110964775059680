import styled from 'styled-components'
import { breakpoints } from '../utils/breakpoints'

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  max-width: 1080px;
  padding: 30px;
  @media (min-width: ${breakpoints.small}px) {
    max-width: 100%;
  }

  @media (min-width: ${breakpoints.medium}px) {
    max-width: 100%;
  }

  @media (min-width: ${breakpoints.large}px) {
    max-width: 1600px;
  }
`

export const MainContainer = styled.div`
  min-height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 30px;
  margin: 0 auto;
  width: 100%;

  @media (min-width: ${breakpoints.small}px) {
    max-width: 100%;
  }

  @media (min-width: ${breakpoints.medium}px) {
    max-width: 100%;
  }

  @media (min-width: ${breakpoints.large}px) {
    max-width: 1600px;
  }
`
