import {
  ApplicationInsights,
  SeverityLevel,
} from '@microsoft/applicationinsights-web'
import {
  ReactPlugin,
  withAITracking as aiWithAITracking,
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js'

let reactPlugin: ReactPlugin
let appInsights: ApplicationInsights | null = null
export const aiTrackingEnabled =
  (process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING || '') !== ''

// Configure AI based on environment variable, if nothing set then don't intialise
if (aiTrackingEnabled) {
  globalThis.console.log(
    `Initialising Application Insights ${
      process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING?.substring(
        19,
        19 + 8
      ) || 'nokey'
    } `
  )

  reactPlugin = new ReactPlugin()
  appInsights = new ApplicationInsights({
    config: {
      connectionString:
        process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
      extensions: [reactPlugin],
      isBrowserLinkTrackingEnabled: true,
      loggingLevelTelemetry: 2,
      enableAutoRouteTracking: true,
      enableCorsCorrelation: true,
      correlationHeaderDomains: [
        '^(styra|talva).*.azurewebsites.net$',
        'nemlia.com$',
        ...(process.env.REACT_APP_TALVA_ENVIRONMENT !== 'production'
          ? ['localhost']
          : []),
      ],
      correlationHeaderExcludedDomains: ['auth.nemlia.com$'],
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableAjaxPerfTracking: true,
      enableAjaxErrorStatusText: true,
    },
  })
  appInsights.loadAppInsights()

  appInsights.addDependencyInitializer((details: any) => {
    // Supress normal hotupdate calls
    if (
      details.item.target?.match(/.webpack.hot-update.json$/) &&
      details.item.success
    ) {
      return false
    }
  })

  //
  // Overload the console and redirect to AI
  const console = (function (oldCons) {
    const logReplacer = (old: Function, level: SeverityLevel) => (
      ...text: any[]
    ) => {
      old.apply(oldCons, text)
      if (level !== SeverityLevel.Error) {
        appInsights?.trackTrace({
          message: replaceStr(text),
          severityLevel: level,
        })
      } else {
        appInsights?.trackException({
          exception: isError(text[0]) ? text[0] : new Error(replaceStr(text)),
          severityLevel: level,
        })
      }
    }
    return {
      ...oldCons,
      log: logReplacer(oldCons.log, SeverityLevel.Verbose),
      error: logReplacer(oldCons.error, SeverityLevel.Error),
      warn: logReplacer(oldCons.warn, SeverityLevel.Warning),
      info: logReplacer(oldCons.info, SeverityLevel.Information),
    }
  })(globalThis.console)
  globalThis.console = console
}

// Set the user
export function setAuthenticatedUser(id: string): void {
  if (appInsights) {
    const validatedId = id.replace(/[,;=| ]+/g, '_')
    appInsights.setAuthenticatedUserContext(validatedId)
  }
}

// Allow configuring of initialiser
export const addDependencyInitializer = appInsights
  ? appInsights.addDependencyInitializer
  : () => undefined

// Helper function to log an exception
export function exception(
  error: string | Error,
  properties: Record<string, unknown> = {}
) {
  if (appInsights) {
    const exception = isError(error) ? error : new Error(error)
    appInsights.trackException(
      {
        exception,
      },
      {
        ...properties,
      }
    )
  }
  console.log(
    new Date().toISOString(),
    'ERROR',
    error,
    JSON.stringify(properties)
  )
}

// Create the container and information required to implement ApplicationInsights on a page
// this is only used if AI is enabled
const AppInsights = ({ children }: { children: any }) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => <h1>I believe something went wrong</h1>}
        appInsights={reactPlugin}
      >
        {children}
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  )
}

// To instrument various React components usage tracking, apply the `withAITracking` higher-order
// component function.
export function withAITracking(element: any) {
  return appInsights ? aiWithAITracking(reactPlugin, element) : element
}

// And export our own tracking wrapper to setup the application
export default withAITracking(
  appInsights ? AppInsights : ({ children }: { children: any }) => children
)

//
// Helper functions for simplifying the console log outputs for
// transfer into application insights
function deObject(placeholders: any[]) {
  return placeholders.map((a) => (typeof a == 'string' ? a : JSON.stringify(a)))
}

function replaceStr(placeholders: any[]) {
  // 1. handle substitutions without discarding
  const str = placeholders.shift()
  const replaced =
    typeof str == 'string'
      ? str.replace(/%([sfdioO])/g, (m: string) => {
          if (m === 'o' || m === 'O') {
            return JSON.stringify(placeholders.shift())
          }
          return placeholders.shift()
        })
      : JSON.stringify(str)
  // then join any remaining data converting objects if needed
  return [replaced, ...deObject(placeholders)].join(' ')
}

// https://stackoverflow.com/questions/30469261/checking-for-typeof-error-in-js
function isError(e: any): e is Error {
  return e && Object.prototype.toString.call(e) === '[object Error]'
}
