import { gql } from '@apollo/client'

interface User {
  id: string
  user_language: string
}

interface CitizenUser {
  id: string
  ta_user: User
}

interface Tracker {
  id: string
}

export interface Citizen {
  id: string
  citizen_name: string
  citizen_address: string
  citizen_image_file: string
  ta_trackers: Tracker[]
  ta_citizen_users: CitizenUser[]
  measure_view?: string
}

export interface CitizenInput {
  email: string
}

export interface CitizenResponse {
  ta_citizen: Citizen[]
}

export const CITIZEN_QUERY = gql`
  query CitizenQuery($email: String!) {
    ta_citizen(
      where: {
        archived: { _eq: false }
        ta_citizen_users: {
          ta_user: { email: { _eq: $email } }
          archived: { _eq: false }
        }
      }
    ) {
      id
      citizen_name
      citizen_address
      citizen_image_file
      ta_trackers {
        id
      }
      ta_citizen_users(where: { ta_user: { email: { _eq: $email } } }) {
        id
        ta_user {
          id
          full_name
          user_language
          user_phone
          user_timezone
          email
          show_locate
        }
      }
      measure_view
    }
  }
`
